import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";

import * as analytics from "js/analytics";
import { getStaticUrl } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { User } from "js/core/models/user";
import { getExperiments } from "js/core/services/experiments";
import { RemoveSplashScreen } from "js/editor/SplashScreen";
import { app } from "js/namespaces";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import CheckoutSignup from "js/react/views/UserOptions/Billing/CheckoutSignup";
import CreateWorkspaceDialog from "js/react/views/UserOptions/Billing/CreateWorkspaceDialog";
import EnterpriseInviteDialog from "js/react/views/UserOptions/Billing/EnterpriseInviteDialog";
import { trackActivity } from "js/core/utilities/utilities";
import stripeProducts from "common/stripeProducts";

import "css/billing.scss";
import "css/components.scss";
import "css/controls.scss";
import "css/dialogs.scss";

const logger = getLogger(LogGroup.CHECKOUT);

const Container = styled.div.attrs(({ withImageBackground }) => ({
    style: {
        background: withImageBackground ? `url(${getStaticUrl("/images/onboarding/slide-grid.jpg")}) no-repeat top right` : "white",
        backgroundSize: withImageBackground ? "962px 1198px" : "unset"
    }
}))`
    height: 100vh;
`;

class Checkout extends React.Component {
    state = {
        loading: true,
        plan: null,
        priceId: null
    };

    async componentDidMount() {
        const { firebaseUser } = this.props;

        const plan = this.props.plan ?? "pro";
        let priceId = null;

        try {
            // Migration logic to replace deprecated price ids with the new ones as
            // we still may have marketing links that point to deprecated prices
            if (this.props.priceId) {
                const priceIds = this.props.priceId.split(" ").map(id => id?.trim()).filter(Boolean);
                const prices = stripeProducts[plan].plans.filter(p => priceIds.includes(p.planId));
                for (const idx in prices) {
                    const price = prices[idx];
                    if (price.isDeprecated) {
                        logger.warn(`rendered with deprecated price id ${price.planId}, will use ${price.migrateTo}`);
                        const migratedPrice = stripeProducts[plan].plans.find(p => p.planId === price.migrateTo);
                        prices[idx] = migratedPrice;
                    }
                }
                priceId = prices.length > 0 ? prices.map(p => p.planId).join(" ") : null;
            }
            //////////////////////////

            const {
                onboarding_plan_confirm: { enabled: showPlan },
            } = await getExperiments(["onboarding_plan_confirm"]);

            app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });

            ds.checkoutSetup();

            await app.user.load();

            await analytics.identify(firebaseUser);

            RemoveSplashScreen(true);

            this.setState({
                plan,
                priceId,
                loading: false
            });

            if (plan !== "enterprise" && showPlan && !app.user.get("didConfirmPlan") && !app.user.hasSubscription() && !app.user.get("hasTakenTeamTrial")) {
                // Special case to make Cameron's life easier
                trackActivity("SignupFlow", "NavForward", null, null, {
                    step_number: 7,
                    step_name: "Choose Plan",
                    plan_selected: plan ?? "pro",
                    object: "button",
                    object_label: plan === "team" ? "Team" : "Pro",
                    action: "clicked",
                    workspace_id: "all"
                }, { audit: true });
            }
        } catch (err) {
            logger.error(err, "load failed", { uid: firebaseUser.uid, plan, priceId });

            ShowWarningDialog({
                title: "An error occurred while loading user data",
            });
        }
    }

    render() {
        const { billingInterval, displayName, quantity, enterpriseInviteId, closeDialog, hideBackdrop = true } = this.props;
        const { loading, plan, priceId } = this.state;

        if (loading) {
            return <div></div>;
        }

        const checkoutFormProps = {
            defaultBillingInterval: billingInterval,
            productDisplayName: displayName,
            quantity,
            priceId,
            closeDialog,
            hideBackdrop,
            enterpriseInviteId
        };

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <Container>
                    {plan === "pro" && <CheckoutSignup {...checkoutFormProps} />}
                    {plan === "team" && <CreateWorkspaceDialog {...checkoutFormProps} />}
                    {plan === "enterprise" && <EnterpriseInviteDialog {...checkoutFormProps} />}
                </Container>
            </MuiThemeProvider>
        );
    }
}

export default withFirebaseUser(Checkout);
